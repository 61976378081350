import {IProduct, ProductIdToProductPageUrlMap, GALLERY_TYPE, IGalleryControllerConfig} from '../types/galleryTypes';
import {
  TRACK_EVENT_COLLECTION,
  translationPath,
  GALLERY_PUBLIC_DATA_PRESET_ID,
  DEFAULT_COLLECTION_ID,
  Experiments,
} from '../constants';
import {IGalleryStyleParams, ITextsMap, IPropsInjectedByViewerScript} from '../types/sliderGalleryTypes';
import {ProductsService} from '../services/ProductsService';
import {SiteStore} from '@wix/wixstores-client-core/dist/es/src/viewer-script/site-store/siteStore';
import {getTranslations} from '@wix/wixstores-client-core/dist/src/viewer-script/utils';
import {getStyleParamsWithDefaults} from '@wix/wixstores-client-common-components/dist/src/outOfIframes/defaultStyleParams/getStyleParamsWithDefaults';
import {APP_DEFINITION_ID, PageMap, PubSubEvents} from '@wix/wixstores-client-core/dist/es/src/constants';
import {getStyleParamsWithDefaultsFunc} from '../getStyleParamsWithDefaultsFunc';
import {MultilingualService} from '@wix/wixstores-client-core/dist/src/multilingualService/multilingualService';
import {IWidgetControllerConfig} from '@wix/native-components-infra/dist/es/src/types/types';
import {isWorker} from '@wix/wixstores-client-core/dist/es/src/viewer-script/utils';

export class SliderGalleryStore {
  private readonly fedopsLogger;
  private mainCollectionId: string;
  private multilingualService: MultilingualService;
  private productPageSectionUrl: string;
  private translations;
  private isFedopsReport: boolean = true;
  private productIdToProductPageUrlMap: ProductIdToProductPageUrlMap;
  private relatedProductIds: string[] = [];
  private readonly MAX_PRODUCTS_IN_SLIDER = 32;

  constructor(
    public styleParams: IGalleryStyleParams,
    private readonly config: IWidgetControllerConfig['config'],
    private readonly updateComponent: (props: Partial<IPropsInjectedByViewerScript>) => void,
    private readonly siteStore: SiteStore,
    private readonly productsService: ProductsService,
    private readonly compId: string,
    private readonly reportError: (e) => any,
    private readonly type: string
  ) {
    const fedopsLoggerFactory = this.siteStore.platformServices.fedOpsLoggerFactory;
    this.fedopsLogger = fedopsLoggerFactory.getLoggerForWidget({
      appId: APP_DEFINITION_ID,
      widgetId: this.type,
    });
    if (isWorker()) {
      this.fedopsLogger.appLoadStarted();
    }
    //todo: COMPONENT === null is not tested, be this check can be removed after bolt will stop sending nulls https://wix.slack.com/archives/CAKBA7TDH/p1555852184003900
    this.config.publicData.COMPONENT = this.config.publicData.COMPONENT || {};

    this.subscribe();
  }

  public async getCateogryProducts({limit, offset}: {limit: number; offset?: number}): Promise<void> {
    await this.productsService.getCategoryProducts({compId: this.compId, limit, offset});
    this.productIdToProductPageUrlMap = this.generateProductIdToProductPageUrlMap(this.productsService.products);
    this.updateComponent({
      products: this.productsService.products,
      productIdToProductPageUrlMap: this.productIdToProductPageUrlMap,
    });
  }

  private padProductListWithPlaceholders() {
    if (this.productsService.products.length < this.effectiveTotalCount) {
      const realLength = this.productsService.products.length;
      this.productsService.products[this.effectiveTotalCount - 1] = undefined;
      this.productsService.products.fill({isFake: true} as any, realLength, this.effectiveTotalCount);
    }
  }

  public async setInitialState(forceRender: boolean = false): Promise<void> {
    if (!forceRender && !this.shouldComponentRender()) {
      return;
    }

    const numOfColumns = this.config.style.styleParams.numbers.galleryColumns || 4;
    const numOfSets = 2;

    const fetcher = this.getDataFetcher();
    const [translations, response, {url}] = await Promise.all([
      getTranslations(translationPath(this.siteStore.baseUrls.galleryBaseUrl, this.siteStore.locale)),
      fetcher(),
      this.siteStore.getSectionUrl(PageMap.PRODUCT),
    ]).catch(this.reportError);

    if (this.galleryType !== GALLERY_TYPE.RELATED_PRODUCTS && this.effectiveTotalCount > numOfSets * numOfColumns) {
      this.padProductListWithPlaceholders();
      await this.productsService.getCategoryProducts({
        compId: this.compId,
        limit: numOfColumns * numOfSets,
        offset: this.effectiveTotalCount - numOfColumns * numOfSets,
      });
    }

    const {products} = this.productsService;

    this.translations = translations;
    this.productPageSectionUrl = url;
    this.mainCollectionId = this.productsService.getMainCollectionId();
    this.productIdToProductPageUrlMap = this.generateProductIdToProductPageUrlMap(products);

    this.multilingualService = new MultilingualService(
      this.config.publicData.COMPONENT,
      response.appSettings.widgetSettings,
      this.siteStore.getMultiLangFields(),
      this.siteStore.locale
    );

    const props = this.getPropsToInject(products);
    this.updateComponent(props);

    if (this.siteStore.isSSR()) {
      this.fedopsLogger.appLoaded();
    }
  }

  public onAppLoaded(): void {
    /* istanbul ignore next: hard to test it */
    if (this.isFedopsReport) {
      this.fedopsLogger.appLoaded();
      this.isFedopsReport = false;
    }
  }

  private get effectiveTotalCount(): number {
    return Math.min(this.productsService.totalCount, this.MAX_PRODUCTS_IN_SLIDER);
  }

  private getPropsToInject(products: IProduct[]): IPropsInjectedByViewerScript {
    return {
      cssBaseUrl: this.siteStore.baseUrls.galleryBaseUrl,
      getCategoryProducts: this.getCateogryProducts.bind(this),
      handleProductItemClick: this.handleProductItemClick.bind(this),
      isFirstPage: true,
      isInteractive: this.siteStore.isInteractive(),
      isLiveSiteMode: this.siteStore.isSiteMode(),
      isLoaded: true,
      showShowLightEmptyState: this.productsService.showShowLightEmptyState,
      isMobile: this.siteStore.isMobile(),
      isRTL: this.siteStore.isRTL(),
      mainCollectionId: this.mainCollectionId,
      onAppLoaded: this.onAppLoaded.bind(this),
      openQuickView: this.openQuickView.bind(this),
      productIdToProductPageUrlMap: this.productIdToProductPageUrlMap,
      products,
      showTitle: this.shouldShowTitle,
      ravenUserContextOverrides: {id: this.siteStore.storeId},
      textsMap: this.getTextsMap(),
      totalProducts: this.effectiveTotalCount,

      experiments: {
        isArrowlessMobileSliderEnabled: this.siteStore.experiments.enabled(
          Experiments.ClientGalleryArrowlessMobileSlider
        ),
      },
    };
  }

  private getTextsMap(): ITextsMap {
    return {
      noProductsMessageText: this.translations.NO_PRODUCTS_MESSAGE_MAIN,
      productOutOfStockText: this.translations.OUT_OF_STOCK_LABEL,
      productPriceAfterDiscountSR: this.translations['sr.PRODUCT_PRICE_AFTER_DISCOUNT'],
      productPriceBeforeDiscountSR: this.translations['sr.PRODUCT_PRICE_BEFORE_DISCOUNT'],
      productPriceWhenThereIsNoDiscountSR: this.translations['sr.PRODUCT_PRICE_WHEN_THERE_IS_NO_DISCOUNT'],
      quickViewButtonText: this.translations.QUICK_VIEW,
      sliderGalleryNoProductsMessageText: this.translations.NO_PRODUCTS_MESSAGE_MINI_GALLERY_MAIN,
      sliderGalleryTitle: this.title,
      sliderGalleryNextProduct: this.translations['sr.sliderGallery.nextProduct'],
      sliderGalleryPreviousProduct: this.translations['sr.sliderGallery.previousProduct'],
      digitalProductBadgeAriaLabelText: this.translations['sr.digitalProduct'],
    };
  }

  private generateProductIdToProductPageUrlMap(products: IProduct[]): ProductIdToProductPageUrlMap {
    const res = {};

    products
      .filter(product => product && product.urlPart)
      .forEach(p => {
        res[p.id] = `${this.productPageSectionUrl}/${p.urlPart}`;
      });

    return res;
  }

  private openQuickView({productId, index}: {productId: string; index: number}) {
    const product = this.productsService.getProduct(productId);
    this.siteStore.biLogger.clickedOnProductQuickViewSf({
      productId,
      hasRibbon: !!product.ribbon,
      hasOptions: !!product.options.length,
      index,
    }); // tslint:disable-line no-floating-promises
    this.productsService.quickViewProduct(productId, product.urlPart, this.compId, this.config.externalId); // tslint:disable-line no-floating-promises
    this.sendClickTrackEvent(product, index);
  }

  private sendClickTrackEvent(product: IProduct, index: number) {
    this.siteStore.windowApis.trackEvent('ClickProduct', {
      appDefId: APP_DEFINITION_ID,
      id: product.id,
      origin: 'Stores',
      name: product.name,
      list: 'Grid Gallery',
      category: TRACK_EVENT_COLLECTION,
      position: index,
      price: product.comparePrice || product.price,
      currency: this.siteStore.currency,
    });
  }

  private handleProductItemClick({
    biData: {productId, index},
  }: {
    biData: {
      productId: string;
      index: number;
    };
  }) {
    const product = this.productsService.getProduct(productId);

    this.productsService.storeNavigation(this.siteStore.siteApis.currentPage.id);

    this.siteStore.biLogger.clickOnProductBoxSf({
      productId,
      hasRibbon: !!product.ribbon,
      hasOptions: !!product.options.length,
      index,
      productType: product.productType,
    }); // tslint:disable-line no-floating-promises

    this.siteStore.navigate({
      sectionId: PageMap.PRODUCT,
      state: product.urlPart,
      queryParams: undefined,
    }); // tslint:disable-line no-floating-promises
    this.sendClickTrackEvent(product, index);
  }

  public updateState(
    newStyleParams: IGalleryStyleParams,
    newPublicData: IGalleryControllerConfig['publicData'] & {appSettings?: any}
  ): void {
    this.config.publicData = newPublicData;
    this.multilingualService.setPublicData(newPublicData.COMPONENT);
    this.styleParams = getStyleParamsWithDefaults(newStyleParams, () =>
      getStyleParamsWithDefaultsFunc({style: {styleParams: newStyleParams}, dimensions: undefined})
    );

    if (newPublicData.appSettings) {
      this.multilingualService.setWidgetSettings(newPublicData.appSettings);
    }

    this.updateComponent({
      showTitle: this.shouldShowTitle,
      textsMap: this.getTextsMap(),
    });
  }

  private get galleryType() {
    if (this.config.publicData.COMPONENT.presetId === GALLERY_PUBLIC_DATA_PRESET_ID.RELATED_PRODUCTS) {
      return GALLERY_TYPE.RELATED_PRODUCTS;
    }

    return GALLERY_TYPE.COLLECTION;
  }

  private getDataFetcher() {
    return {
      [GALLERY_TYPE.COLLECTION]: () =>
        this.productsService.oldGetInitialData({
          externalId: this.config.externalId,
          compId: this.compId,
        }),
      [GALLERY_TYPE.RELATED_PRODUCTS]: () =>
        this.productsService.getRelatedItems({
          externalId: this.config.externalId,
          productIds: this.relatedProductIds,
        }),
    }[this.galleryType];
  }

  private subscribe() {
    const forceRenderWhenEmpty = () => this.relatedProductIds.length === 0 && this.renderRelatedProducts();

    if (this.galleryType === GALLERY_TYPE.RELATED_PRODUCTS) {
      setTimeout(forceRenderWhenEmpty, 500);
      this.siteStore.pubSub.subscribe(
        PubSubEvents.RELATED_PRODUCTS,
        response => this.renderRelatedProducts(response.data),
        true
      );
    }
  }

  private renderRelatedProducts(productIds: string[] = []) {
    this.relatedProductIds = [...productIds];
    this.setInitialState(true); // tslint:disable-line no-floating-promises
  }

  private shouldComponentRender() {
    if (!this.siteStore.isInteractive()) {
      return true;
    }
    if (this.galleryType === GALLERY_TYPE.RELATED_PRODUCTS && this.relatedProductIds.length === 0) {
      return false;
    }
    return true;
  }

  private get title(): string {
    if (this.galleryType === GALLERY_TYPE.COLLECTION) {
      const collectionName =
        this.productsService.getMainCollectionId() === DEFAULT_COLLECTION_ID
          ? this.translations['gallery.collection.allProducts']
          : this.productsService.collectionName;

      return this.multilingualService.get('SLIDER_GALLERY_TITLE_COLLECTION') || collectionName;
    }

    return (
      this.multilingualService.get('SLIDER_GALLERY_TITLE_RELATED_PRODUCTS') ||
      this.translations['gallery.relatedProducts.default.title']
    );
  }

  private get shouldShowTitle() {
    if (this.styleParams.booleans.gallery_showTitle !== undefined) {
      return this.styleParams.booleans.gallery_showTitle;
    }
    return this.galleryType === GALLERY_TYPE.RELATED_PRODUCTS;
  }
}
